import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Clock, Activity, Award, TrendingDown, Info, Calendar, Target, ErrorCircle, BarChart3, Moon, Sun, Paperclip, Share2, Trophy, Youtube } from 'lucide-react';
import { Modal, Button, Form } from 'react-bootstrap';
import YouTube from 'react-youtube';
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import 'bootstrap/dist/css/bootstrap.min.css';
import './speedrun.css';
import TimeSaveChart from './TimeSaveChart';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import Leaderboard from './Leaderboard';
import RunVisualization from './RunVisualization';
import { formatTime, formatDiff, getDiffColor, COLORS, parseTime, formatYouTubeTimestamp } from './utils/utils';
import SegmentDetail from './SegmentDetail';

  const MemoizedTwitchPlayer = React.memo(React.forwardRef(({ videoId, startTime }, ref) => (
    <ReactTwitchEmbedVideo
      video={videoId}
      width="100%"
      height="390"
      time={`${Math.floor(parseFloat(startTime) || 0)}s`}
      onReady={(player) => {
        if (ref) {
          ref.current = player;
        }
      }}
    />
  )));

const parseLssFile = (content) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(content, "text/xml");

  const attempts = Array.from(xmlDoc.getElementsByTagName("Attempt")).map(attempt => ({
    id: parseInt(attempt.getAttribute("id")),
    startedDate: new Date(attempt.getAttribute("started")),
    endedDate: new Date(attempt.getAttribute("ended")),
    realTime: attempt.getElementsByTagName("RealTime")[0]?.textContent,
    gameTime: attempt.getElementsByTagName("GameTime")[0]?.textContent,
    isFinished: !!(attempt.getElementsByTagName("RealTime")[0] || attempt.getElementsByTagName("GameTime")[0]),
  }));

  const finishedAttempts = attempts.filter(attempt => attempt.realTime || attempt.gameTime);

  const segments = Array.from(xmlDoc.getElementsByTagName("Segment")).map(segment => ({
    name: segment.getElementsByTagName("Name")[0]?.textContent,
    bestRealTime: segment.getElementsByTagName("BestSegmentTime")[0]?.getElementsByTagName("RealTime")[0]?.textContent,
    bestGameTime: segment.getElementsByTagName("BestSegmentTime")[0]?.getElementsByTagName("GameTime")[0]?.textContent,
    segmentHistory: Array.from(segment.getElementsByTagName("SegmentHistory")[0]?.getElementsByTagName("Time")).map(time => ({
      id: time.id || '',
      realTime: time.getElementsByTagName("RealTime")[0]?.textContent || '',
      gameTime: time.getElementsByTagName("GameTime")[0]?.textContent || ''
    })),
  }));

  return { attempts, segments };
};


const calculateAverageSegmentTimes = (segments, useGameTime) => {
  const calculateRollingMean = (times, windowSize) => {
    const rollingMeans = [];
    for (let i = 0; i <= times.length - windowSize; i++) {
      const window = times.slice(i, i + windowSize);
      const mean = window.reduce((sum, time) => sum + time, 0) / windowSize;
      rollingMeans.push(mean);
    }
    return rollingMeans;
  };

  const findStabilizationIndex = (times, windowSize, thresholdFactor) => {
    const rollingMeans = calculateRollingMean(times, windowSize);
    for (let i = 0; i < rollingMeans.length; i++) {
      if (rollingMeans[i] <= thresholdFactor * Math.min(...times)) {
        return i + windowSize - 1;
      }
    }
    return 0;
  };

  const removeOutliers = (times) => {
    if (times.length <= 10) return times; // Not enough data to reliably remove outliers

    times.sort((a, b) => a - b);
    const q1 = times[Math.floor(times.length / 4)];
    const q3 = times[Math.ceil(times.length * 3 / 4)];
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;

    return times.filter(time => time <= upperBound);
  };

  return segments.reduce((acc, segment) => {
    const times = segment.segmentHistory
      .map(history => parseTime(useGameTime ? history.gameTime : history.realTime))
      .filter(time => time > 0);

      if (times.length >= 15) {
        // Detect stabilization point
        const windowSize = 5; // Size of the rolling window
        const thresholdFactor = 1.5; // Factor to define stabilization
        const stableIndex = findStabilizationIndex(times, windowSize, thresholdFactor);
    
        // Remove initial high times
        // const stableTimes = times.slice(stableIndex);
        const stableTimes = stableIndex === 4 ? times : times.slice(stableIndex);
    
        // Remove outliers from the stable times
        const filteredTimes = removeOutliers(stableTimes);
        acc[segment.name] = filteredTimes.reduce((sum, time) => sum + time, 0) / filteredTimes.length;
      } else {
      acc[segment.name] = times.reduce((sum, time) => sum + time, 0) / times.length;
      }
    return acc;
  }, {});
};


const findBestRun = (attempts, useGameTime) => {
    return attempts
      .filter(attempt => attempt.isFinished)
      .filter(attempt => {
        if (useGameTime) {
          return attempt.gameTime;
        } else {
          return attempt.realTime;
        }
      })
      .reduce((best, attempt) => {
        const time = parseTime(useGameTime ? attempt.gameTime : attempt.realTime);
        return !best || time < best.time ? { ...attempt, time } : best;
      }, null);
  };

  const getSegmentsForBestRun = (segments, bestRunId, useGameTime) => {
    return segments.map(segment => {
        const bestRunHistory = segment.segmentHistory.find(history => Number(history.id) === bestRunId);
        //console.log(segment.name);
        return {
            name: segment.name,
            timeInSeconds: parseTime(useGameTime ? bestRunHistory?.gameTime : bestRunHistory?.realTime),
             realTimeInSeconds: parseTime(bestRunHistory?.realTime),
        };
    });
};



const calculateSumOfBestSegments = (segments, useGameTime) => {
    //console.log(segments);
  return segments.reduce((sum, segment) => sum + parseTime(useGameTime ? segment.bestGameTime : segment.bestRealTime), 0);
};

const StraySpeedrunDashboard = () => {
  const [attempts, setAttempts] = useState([]);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [visibleSegmentDetails, setVisibleSegmentDetails] = useState(null); // State to manage visibility of segment details
  const [useGameTime, setUseGameTime] = useState(true);
  const [gameTimeAvailable, setGameTimeAvailable] = useState(true);
  const [selectedRun, setSelectedRun] = useState(null);
  const [darkMode, setDarkMode] = useState(true);
  const [shareableLink, setShareableLink] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoStartTime, setVideoStartTime] = useState('');
  const [videoType, setVideoType] = useState('');
  const [videoId, setVideoId] = useState('');
  const youtubePlayerRef = useRef(null);
  const [twitchPlayer, setTwitchPlayer] = useState(null);
  const twitchPlayerRef = useRef(null);

  const [gameName, setGameName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [gameInfo, setGameInfo] = useState(null);
  const [selectedPieSegment, setSelectedPieSegment] = useState(null);

  const [showTimestampsModal, setShowTimestampsModal] = useState(false);
  const [timestamps, setTimestamps] = useState('');

  

  
  const averageSegmentTimes = calculateAverageSegmentTimes(segments, useGameTime);

  const generateTimestamps = () => {
    if (!segmentsForBestRun || segmentsForBestRun.length === 0) return '';
    let currentTime = parseFloat(videoStartTime) || 0;
    let timestampString = '';
    
    // Only add 'Intro' if the first segment doesn't start at 0:00
    if (currentTime > 0) {
      timestampString = '0:00 Intro\n';
    }
  
    let combinedSegment = { name: '', realTimeInSeconds: 0 };
    
    for (let i = 0; i < segmentsForBestRun.length; i++) {
      const segment = segmentsForBestRun[i];
      combinedSegment.name += (combinedSegment.name ? ' + ' : '') + segment.name;
      combinedSegment.realTimeInSeconds += segment.realTimeInSeconds;
  
      // If the combined segment duration is at least 10 seconds or it's the last segment
      if (combinedSegment.realTimeInSeconds / 1000 >= 10 || i === segmentsForBestRun.length - 1) {
        const totalSeconds = Math.floor(currentTime);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        
        const formattedTime = hours > 0 
          ? `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
          : `${minutes}:${seconds.toString().padStart(2, '0')}`;
        
        timestampString += `${formattedTime} ${combinedSegment.name}\n`;
        currentTime += combinedSegment.realTimeInSeconds / 1000; // Convert milliseconds to seconds
        
        // Reset the combined segment for the next iteration
        combinedSegment = { name: '', realTimeInSeconds: 0 };
      }
    }
    
    return timestampString.trim();
  };
  
  

  const handleShowTimestamps = () => {
    const generatedTimestamps = generateTimestamps();
    setTimestamps(generatedTimestamps);
    setShowTimestampsModal(true);
  };




  useEffect(() => {
    if (videoType === 'twitch' && videoId) {
      setTwitchPlayer(
        <MemoizedTwitchPlayer
          videoId={videoId}
          startTime={videoStartTime}
          ref={twitchPlayerRef}
        />
      );
    } else {
      setTwitchPlayer(null);
    }
  }, [videoType, videoId, videoStartTime]);

  const handleVideoAttach = () => {
    setShowVideoModal(true);
  };

  const handleVideoSubmit = () => {
    const { type, id } = extractVideoInfo(videoUrl);
    setVideoType(type);
    setVideoId(id);
    setShowVideoModal(false);
  };

  const extractVideoInfo = (url) => {
    const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const twitchRegExp = /^(?:https?:\/\/)?(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
    
    const youtubeMatch = url.match(youtubeRegExp);
    const twitchMatch = url.match(twitchRegExp);

    if (youtubeMatch && youtubeMatch[2].length === 11) {
      return { type: 'youtube', id: youtubeMatch[2] };
    } else if (twitchMatch) {
      return { type: 'twitch', id: twitchMatch[1] };
    }
    
    return { type: '', id: '' };
  };

  const jumpToVideoTime = useCallback((timeInSeconds) => {
    const startTimeInSeconds = parseFloat(videoStartTime) || 0;
    const targetTime = startTimeInSeconds + timeInSeconds  / 1000;

    if (videoType === 'youtube' && youtubePlayerRef.current) {
      youtubePlayerRef.current.seekTo(targetTime);
    } else if (videoType === 'twitch' && twitchPlayerRef.current) {
      twitchPlayerRef.current.seek(targetTime);
    }
  }, [videoType, videoStartTime]);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      setDarkMode(savedMode === 'true');
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  
  const handleFileUpload = useCallback((event) => {
    setGameInfo(null);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, "text/xml");
        
        // Extract game name and category
        const gameNameElement = xmlDoc.getElementsByTagName("GameName")[0];
        const categoryNameElement = xmlDoc.getElementsByTagName("CategoryName")[0];
        
        if (gameNameElement) setGameName(gameNameElement.textContent);
        if (categoryNameElement) setCategoryName(categoryNameElement.textContent);
        
        const parsedData = parseLssFile(content);
        setAttempts(parsedData.attempts);
        setSegments(parsedData.segments);
        //console.log(attempts);

        // Check if any attempt has GameTime
        const hasGameTime = parsedData.attempts.some(attempt => attempt.gameTime !== undefined);

        // Set useGameTime based on whether any attempt has GameTime
        setUseGameTime(hasGameTime);
        // Set a new state to indicate if game time is available
        setGameTimeAvailable(hasGameTime);

        // Fetch game info
        if (gameNameElement && categoryNameElement) {
          fetchGameInfo(gameNameElement.textContent, categoryNameElement.textContent);
        }
      };
      reader.readAsText(file);
    }
  }, []);

  const fetchGameInfo = async (name, category) => {
    try {
      const response = await fetch(`/api/games_api.php?name=${encodeURIComponent(name)}&category=${encodeURIComponent(category)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
  
      // Check if the response contains an error message
      if (data.error) {
        setGameInfo(null);
      } else {
        setGameInfo(data);
          if (data.bgimage) {
            document.body.style.backgroundImage = `url('/background/${data.bgimage}')`;
          } else {
            document.body.style.backgroundImage = 'none';
          }
      }
    } catch (error) {
      console.error("Failed to fetch game info:", error);
      setGameInfo(null);  // Also set to null in case of network error
    }
  };
  

  const handleSaveAndGetLink = async () => {
    try {
      const parsedData = {
        attempts: attempts,
        segments: segments,
        videoData: {
          type: videoType,
          id: videoId,
          startTime: videoStartTime
        },
        gameName: gameName,
        categoryName: categoryName
      };
        const response = await fetch('/api/speedrun_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ parsedData }),
        });
        const { id } = await response.json();
        const link = `${window.location.origin}/splits/${id}`;
        setShareableLink(link);
        setShowShareModal(true);
      } catch (error) {
        console.error('Error saving data:', error);
      }
  };



  
  const loadData = async (id) => {
    try {
      const response = await fetch(`/api/speedrun_api.php?id=${id}`);
      if (!response.ok) {
        throw new Error('Data not found');
      }
      const parsedData = await response.json();
      setAttempts(parsedData.attempts);
      setSegments(parsedData.segments);
      if (parsedData.videoData) {
        setVideoType(parsedData.videoData.type);
        setVideoId(parsedData.videoData.id);
        setVideoStartTime(parsedData.videoData.startTime);
      }
      if (parsedData.gameName) {
        setGameName(parsedData.gameName);
      }
      if (parsedData.categoryName) {
        setCategoryName(parsedData.categoryName);
      }
       const hasGameTime = parsedData.attempts.some(attempt => attempt.gameTime !== undefined);
       setUseGameTime(hasGameTime);
       setGameTimeAvailable(hasGameTime);

      const link = `${window.location.origin}/splits/${id}`;
      setShareableLink(link);
      
      // Fetch game info if game name is available
      if (parsedData.gameName) {
        fetchGameInfo(parsedData.gameName, parsedData.categoryName);
      }
    } catch (error) {
      console.error('Error loading data:', error);
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    const match = path.match(/\/splits\/(.+)/);
    if (match) {
      const id = match[1];
      loadData(id);
    }
  }, []);

  const handleRunClick = (data) => {
    const clickedRun = attempts.find(attempt => attempt.id === data.id);
    const runSegments = getSegmentsForRun(segments, clickedRun.id, useGameTime);
    setSelectedRun({ ...clickedRun, segments: runSegments });
  };

  const getSegmentsForRun = (segments, runId, useGameTime) => {
    let cumulativeSplitTime = 0;
    return segments.map(segment => {
      const runHistory = segment.segmentHistory.find(history => Number(history.id) === runId);
      const segmentTime = parseTime(useGameTime ? runHistory?.gameTime : runHistory?.realTime);
      cumulativeSplitTime += segmentTime;
      return {
        name: segment.name,
        timeInSeconds: segmentTime,
        realTimeInSeconds: parseTime(runHistory?.realTime),
        splitTimeInSeconds: cumulativeSplitTime,
        bestTimeInSeconds: parseTime(useGameTime ? segment.bestGameTime : segment.bestRealTime),
      };
    }).filter(segment => segment.timeInSeconds > 0);
  };

  const getBestSegmentTimes = (segments, useGameTime) => {
    return segments.reduce((acc, segment) => {
      acc[segment.name] = parseTime(useGameTime ? segment.bestGameTime : segment.bestRealTime);
      return acc;
    }, {});
  };

  const bestSegmentTimes = getBestSegmentTimes(segments, useGameTime);


const processedAttempts = attempts.map(attempt => {
    // Check if startedDate is a Date object
    const dateStr = attempt.startedDate instanceof Date 
      ? attempt.startedDate.toISOString().split('T')[0] 
      : attempt.startedDate; // Assume it's already in the correct format if not a Date object
  
    return {
      ...attempt,
      date: dateStr,
      timeInSeconds: parseTime(useGameTime ? attempt.gameTime : attempt.realTime)
    };
  }).sort((a, b) => a.startedDate - b.startedDate)
    .filter(attempt => attempt.isFinished && attempt.timeInSeconds > 0);
  


  const bestTime = Math.min(...processedAttempts.map(a => a.timeInSeconds));
  const worstTime = Math.max(...processedAttempts.map(a => a.timeInSeconds));
  const averageTime = processedAttempts.reduce((sum, a) => sum + a.timeInSeconds, 0) / processedAttempts.length;

  const countSegmentOccurrences = (segments) => {
    return segments.reduce((acc, segment) => {
      if (Array.isArray(segment.segmentHistory)) {
        // acc[segment.name] = segment.segmentHistory.length;
        acc[segment.name] = segment.segmentHistory.filter(item => item.id > 0).length;
      } else {
        console.warn(`Skipping segment "${segment.name}" due to missing or invalid segmentHistory.`);
      }
      return acc;
    }, {});
  };

  const segmentCounts = countSegmentOccurrences(segments);
//   const totalOccurrences = Object.values(segmentCounts).reduce((sum, count) => sum + count, 0);
const totalOccurrences = attempts.length;
  const pieChartData = Object.entries(segmentCounts).map(([name, count]) => ({
    name: name,
    value: (count / totalOccurrences) * 100
  }));

  const handleSegmentClick = (segmentName) => {
    setSelectedSegment(segmentName);
    setVisibleSegmentDetails(visibleSegmentDetails === segmentName ? null : segmentName); // Toggle visibility
  };

  const selectedSegmentData = segments.find(seg => seg.name === selectedSegment);

  const segmentProgressData = selectedSegmentData
  ? selectedSegmentData.segmentHistory
      .map((history, index) => ({
        id: history.id, // assuming sequential order
        timeInSeconds: parseTime(useGameTime ? history.gameTime : history.realTime)
      }))
      .filter(history => history.timeInSeconds > 0 && history.id > 0)
  : [];

  const calculateSegmentStats = (segment) => {
    // const history = segment.segmentHistory.map(history => parseTime(useGameTime ? history.gameTime : history.realTime)).filter(time => time > 0);
    const history = segment.segmentHistory.filter(history => history.id > 0).map(history => parseTime(useGameTime ? history.gameTime : history.realTime)).filter(time => time > 0);

    const totalAttempts = history.length;
    const successfulAttempts = history.filter(time => time > 0).length;
    const bestSegmentTime = Math.min(...history.filter(time => time > 0));
    const averageSegmentTime = history.reduce((sum, time) => sum + time, 0) / totalAttempts;
    const failureRate = attempts.length === 0 ? 0 : (successfulAttempts / attempts.length) * 100;

    return {
      bestTime: bestSegmentTime,
      averageTime: averageSegmentTime,
      failureRate: failureRate,
      count: totalAttempts
    };
  };

  const segmentStats = selectedSegmentData ? calculateSegmentStats(selectedSegmentData) : {};


  // Add this function to find the previous best run
  const findPreviousBestRun = (currentRun) => {
    const sortedRuns = processedAttempts
      .filter(run => run.id !== currentRun.id && run.id < currentRun.id)
      .sort((a, b) => a.timeInSeconds - b.timeInSeconds);
    return sortedRuns[0] || currentRun;
  };

  // Update the bestRun calculation to include the previous best run
  const bestRun = findBestRun(attempts, useGameTime);
  const previousBestRun = findPreviousBestRun(bestRun);
//   const segmentsForBestRun = bestRun ? getSegmentsForBestRun(segments, bestRun.id, useGameTime) : [];
const segmentsForBestRun = bestRun ? getSegmentsForRun(segments, bestRun.id, useGameTime) : [];
  const segmentsForPreviousBestRun = previousBestRun ? getSegmentsForBestRun(segments, previousBestRun.id, useGameTime) : [];
//console.log(segmentsForBestRun);

  //const bestRun = findBestRun(attempts, useGameTime);
  //const segmentsForBestRun = bestRun ? getSegmentsForBestRun(segments, bestRun.id, useGameTime) : [];
  //console.log(bestRun);

  //const pbSegmentData = calculatePersonalBestRun(segments, useGameTime);
  //console.log(pbSegmentData);

  const totalPbTime = segmentsForBestRun.reduce((total, segment) => total + segment.timeInSeconds, 0);
  const sumOfBestSegments = calculateSumOfBestSegments(segments, useGameTime);
  

  return (
    <div className={`main-body container mt-3 ${darkMode ? 'dark-mode' : ''}`}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center"><a href="/"><img src="/logo.webp" className="d-flex me-2" style={{ height: '40px' }}/></a><h1 className={`text-${darkMode ? 'light' : 'primary'} mb-0`}>Speedrun Analytics</h1></div>
        <button onClick={toggleDarkMode} className="btn btn-outline-secondary">
          {darkMode ? <Sun /> : <Moon />}
        </button>
      </div>
      
      <div className="d-flex justify-content-between align-items-center mb-4">
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <label htmlFor="file-upload" className="btn btn-primary">
        Upload .lss File
      </label>
      <input id="file-upload" type="file" accept=".lss" onChange={handleFileUpload} style={{ display: 'none' }} />
      {attempts.length > 0 && (
        <>
          <button className="btn btn-success ms-3 d-flex align-items-center" onClick={handleSaveAndGetLink}>
            <Share2 className="me-2" /> Share
          </button>
        </>
      )}
    </div>
    <div>
      <div className="form-check">
        <input 
          className="form-check-input" 
          type="checkbox" 
          id="useGameTime" 
          checked={useGameTime} 
          onChange={(e) => setUseGameTime(e.target.checked)} 
          disabled={!gameTimeAvailable}
        />
        <label className="form-check-label" htmlFor="useGameTime">
          Use Game Time
        </label>
      </div>
    </div>
  </div>
      
  {gameInfo && attempts.length > 0 && (
        <div className="card mb-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-1">
              <img src={`/cover/${gameInfo.cover}`} alt={gameInfo.name} className="img-fluid" style={{ maxHeight: '110px' }}/>
              </div>
              <div className="col">
                <h2>{gameInfo.name}</h2>
                <p className="card-text fw-bold">Category: {categoryName}</p>
                <p className="card-text fw-bold">World Record: {formatTime(gameInfo.world_record_time)}
                  {/* {gameInfo.world_record_time !== null && (
                <span className={`ms-1 ${getDiffColor(bestTime - gameInfo.world_record_time)}`}>(
                  {formatDiff(bestTime - gameInfo.world_record_time)}
                )</span>
              )} */}
                  </p>
              </div>
              <div className="col">





      {gameInfo && gameInfo.runs && (
        <Leaderboard gameInfo={gameInfo} bestTime={bestTime} />
      )}





    </div>
            </div>
          </div>
        </div>
      )}

      {attempts.length > 0 ? (
        <>
  <div className="row mb-4">
    <div className="col-md-3 mb-3">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <BootstrapTooltip id="personal-best-tooltip">
            <strong>Personal Best:</strong> {formatTime(bestTime)} 
            <br />
            Your fastest completed run. This is the time to beat!
            <br />
            <strong>Compared to Average:</strong> <span className={getDiffColor(bestTime - averageTime)}>{formatDiff(bestTime - averageTime)}</span>
            {gameInfo && gameInfo.world_record_time && (
              <>
                <br />
                <strong>Compared to World Record:</strong> <span className={getDiffColor(bestTime - gameInfo.world_record_time)}>{formatDiff(bestTime - gameInfo.world_record_time)}</span>
              </>
            )}
          </BootstrapTooltip>
        }
      >
        <div className="card p-3 shadow-sm">
          <div className="d-flex align-items-center">
            <Clock className="text-primary me-2" />
            <h5 className="card-title">Personal Best</h5>
          </div>
          <p className="card-text fw-bold">{formatTime(bestTime)}</p>
        </div>
      </OverlayTrigger>
    </div>
    <div className="col-md-3 mb-3">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <BootstrapTooltip id="average-time-tooltip">
            <strong>Average Time:</strong> {formatTime(averageTime)}
            <br />
            The mean time of all your completed runs. This shows your typical performance.
            <br />
            <strong>Compared to PB:</strong> <span className={getDiffColor(averageTime - bestTime)}>{formatDiff(averageTime - bestTime)}</span>
            <br />
            <strong>Compared to Worst:</strong> <span className={getDiffColor(averageTime - worstTime)}>{formatDiff(averageTime - worstTime)}</span>
          </BootstrapTooltip>
        }
      >
        <div className="card p-3 shadow-sm">
          <div className="d-flex align-items-center">
            <Activity className="text-success me-2" />
            <h5 className="card-title">Average Time</h5>
          </div>
          <p className="card-text fw-bold">{formatTime(averageTime)}</p>
        </div>
      </OverlayTrigger>
    </div>
    <div className="col-md-3 mb-3">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <BootstrapTooltip id="total-attempts-tooltip">
            <strong>Total Attempts:</strong> {attempts.length}
            <br />
            <strong>Completed Runs:</strong> {processedAttempts.length}
            <br />
            Total number of runs started. The number in parentheses represents completed runs.
            <br />
            <strong>Completion Rate:</strong> {((processedAttempts.length / attempts.length) * 100).toFixed(2)}%
          </BootstrapTooltip>
        }
      >
        <div className="card p-3 shadow-sm">
          <div className="d-flex align-items-center">
            <Award className="text-warning me-2" />
            <h5 className="card-title">Total Attempts</h5>
          </div>
          <p className="card-text fw-bold">{attempts.length} ({processedAttempts.length})</p>
        </div>
      </OverlayTrigger>
    </div>
    <div className="col-md-3 mb-3">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <BootstrapTooltip id="sum-of-best-tooltip">
            <strong>Sum of Best:</strong> {formatTime(sumOfBestSegments)}
            <br />
            The theoretical best time if you performed each segment at your best.
            <br />
            <strong>Compared to PB:</strong> <span className={getDiffColor(sumOfBestSegments - bestTime)}>{formatDiff(sumOfBestSegments - bestTime)}</span>
            {gameInfo && gameInfo.world_record_time && (
              <>
                <br />
                <strong>Compared to World Record:</strong> <span className={getDiffColor(sumOfBestSegments - gameInfo.world_record_time)}>{formatDiff(sumOfBestSegments - gameInfo.world_record_time)}</span>
              </>
            )}
          </BootstrapTooltip>
        }
      >
        <div className="card p-3 shadow-sm">
          <div className="d-flex align-items-center">
            <Target className="text-info me-2" />
            <h5 className="card-title">Sum of Best</h5>
          </div>
          <p className="card-text fw-bold">{formatTime(sumOfBestSegments)}</p>
        </div>
      </OverlayTrigger>
    </div>
  </div>

          <div className="mb-4">
          <div className="mb-3 d-flex align-items-center">
          <h2 className="">Best Run</h2>
        <button className="btn btn-secondary ms-3 d-flex align-items-center" onClick={handleVideoAttach}>
            <Paperclip className="me-2" /> Attach Video
          </button>
          {videoType === 'youtube' && videoId && (
            <button className="btn btn-danger ms-3 d-flex align-items-center" onClick={handleShowTimestamps}>
              <Youtube className="me-2" /> Timestamps
            </button>
          )}
          </div>

          {videoType === 'youtube' && videoId && (
        <div className="mb-4">
          <YouTube
            videoId={videoId}
            opts={{
              height: '390',
              width: '100%',
              playerVars: {
                autoplay: 0,
                start: Math.floor(parseFloat(videoStartTime) || 0),
              },
            }}
            onReady={(event) => {
              youtubePlayerRef.current = event.target;
            }}
          />
        </div>
      )}

{videoType === 'twitch' && videoId && (
        <div className="mb-4" style={{ height: "390px" }}>
          {twitchPlayer}
        </div>
      )}

        <div className="overflow-auto">
        <RunVisualization 
            segments={segmentsForBestRun} 
            totalTime={totalPbTime} 
            bestSegmentTimes={bestSegmentTimes}
            previousBestRun={segmentsForPreviousBestRun}
            onSegmentClick={jumpToVideoTime}
          />
        </div>
      </div>

<div className="mb-4">
  <h2 className="mb-3">Progress Over Time</h2>
  <ResponsiveContainer width="100%" height={300}>
    <LineChart data={processedAttempts}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis 
        dataKey="id" 
        type="number" // Specify that x-axis is numeric
        domain={['dataMin', 'dataMax']} // Adjust domain as necessary
        tickFormatter={value => value} // Format ticks as needed
        allowDecimals={false}
      />
      <YAxis 
        domain={['auto', 'auto']} 
        tickFormatter={formatTime} 
      />
      <Tooltip formatter={(value) => formatTime(value)} />
      <Line 
        type="monotone" 
        dataKey="timeInSeconds" 
        stroke="#82ca9d" 
        dot={{ r: 5 }} 
        activeDot={{ 
          onClick: (event, payload) => handleRunClick(payload.payload),
          style: { cursor: 'pointer' } // Change cursor to pointer on hover
        }}
      />
    </LineChart>
  </ResponsiveContainer>
</div>

          
<div className="mb-4">
  <h2 className="mb-3">Segment Distribution</h2>
  <div className="row">
    <div className="col-md-8">
      <ResponsiveContainer width="100%" height={550}>
        <PieChart>
          <Pie 
            data={pieChartData} 
            dataKey="value" 
            nameKey="name" 
            cx="50%" 
            cy="50%" 
            outerRadius={220} 
            labelLine={true} 
            label={({ name, value }) => `${name} ${(value).toFixed(0)}%`}
            onClick={(data) => setSelectedPieSegment(data.name)}
          >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
          </Pie>
          <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
        </PieChart>
      </ResponsiveContainer>
    </div>
    <div className="col-md-4">
    <SegmentDetail 
  segment={selectedPieSegment ? segmentsForBestRun.find(s => s.name === selectedPieSegment) : segmentsForBestRun[0]}
  averageSegmentTimes={averageSegmentTimes}
  bestSegmentTimes={bestSegmentTimes}
  attempts={attempts}
  segments={segments}
/>
    </div>
  </div>
</div>
          
          <div className="mb-4">
            <h2 className="mb-3">Best Segments</h2>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                  <th>Segment Name</th>
                <th>Split Time</th>
                <th>Segment Time</th>
                <th>Best Time</th>
                <th><BarChart3 /></th>
                  </tr>
                </thead>
                <tbody>
                  {segmentsForBestRun.map((segment, index) => (
                    <React.Fragment key={index}>
                      <tr 
                        onClick={() => handleSegmentClick(segment.name)}
                        style={{ cursor: 'pointer' }}
                      >
                    <td>{segment.name}</td>
                    <td>{formatTime(segment.splitTimeInSeconds)}</td>
                    <td>{formatTime(segment.timeInSeconds)}</td>
                    <td>{formatTime(segment.bestTimeInSeconds)}</td>
                    <td><BarChart3 /></td>
                      </tr>
                      {visibleSegmentDetails === segment.name && (
                        <tr>
                          <td colSpan="5">
                            <div className="mb-4 p-3">
                              <h2 className="mb-3">Detailed Stats for {segment.name}</h2>
                              <div className="row">
                                <div className="col-md-3 mb-3">
                                  <div className="card p-3 shadow-sm">
                                    <div className="d-flex align-items-center">
                                      <Award className="text-warning me-2" />
                                      <h6 className="card-title">Best Time</h6>
                                    </div>
                                    <p className="card-text">{formatTime(segmentStats.bestTime)}</p>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                  <div className="card p-3 shadow-sm">
                                    <div className="d-flex align-items-center">
                                      <Clock className="text-primary me-2" />
                                      <h6 className="card-title">Average Time</h6>
                                    </div>
                                    <p className="card-text">{formatTime(segmentStats.averageTime)}</p>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                  <div className="card p-3 shadow-sm">
                                    <div className="d-flex align-items-center">
                                      <TrendingDown className="text-danger me-2" />
                                      <h6 className="card-title">Finish Rate</h6>
                                    </div>
                                    <p className="card-text">{segmentStats.failureRate.toFixed(2)}%</p>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                  <div className="card p-3 shadow-sm">
                                    <div className="d-flex align-items-center">
                                      <Info className="text-info me-2" />
                                      <h6 className="card-title">Attempt Count</h6>
                                    </div>
                                    <p className="card-text">{segmentStats.count}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <h2 className="mb-3">Progress Over Time for {segment.name}</h2>
                                <ResponsiveContainer width="100%" height={300}>
                                  <LineChart data={segmentProgressData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="id" />
                                    <YAxis domain={['auto', 'auto']} tickFormatter={formatTime} />
                                    <Tooltip formatter={(value) => formatTime(value)} />
                                    <Line type="monotone" dataKey="timeInSeconds" stroke="#82ca9d" />
                                  </LineChart>
                                </ResponsiveContainer>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <TimeSaveChart
  segments={segmentsForBestRun}
  bestSegmentTimes={bestSegmentTimes}
  averageSegmentTimes={averageSegmentTimes}
/>
          </div>
        </>
      ) : (
        <div className="text-center text-muted mt-5">
          No data available. Please upload a .lss file to view speedrun statistics.
        </div>
      )}

      
{selectedRun && (

        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-50" style={{ zIndex: 1050 }}>
          <div className="bg-white p-4 rounded shadow-sm" style={{ maxWidth: '90vw', width: '100%', maxHeight: '90vh', overflowY: 'auto' }}>
            <h2 className="h5 mb-3">Run Details</h2>
            <div className="row mb-4">
            <div className="col-md-3 mb-3">
                <div className="card p-3 shadow-sm">
                  <div className="d-flex align-items-center">
                  <Award className="text-warning me-2" />
                    <h6 className="card-title">Attempt №</h6>
                  </div>
                  <p className="card-text">{selectedRun.id}</p>
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="card p-3 shadow-sm">
                  <div className="d-flex align-items-center">
                    <Calendar className="text-primary me-2" />
                    <h6 className="card-title">Date</h6>
                  </div>
                  {/* <p className="card-text">{selectedRun.startedDate.toLocaleDateString()}</p> */}
                  <p className="card-text">
  {(() => {
    // Check if startedDate is a Date object
    const date = selectedRun.startedDate instanceof Date
      ? selectedRun.startedDate
      : new Date(selectedRun.startedDate); // Parse string to Date object if needed

    // Use toLocaleDateString on the Date object
    return date.toLocaleDateString();
  })()}
</p>

                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="card p-3 shadow-sm">
                  <div className="d-flex align-items-center">
                    <Clock className="text-success me-2" />
                    <h6 className="card-title">{useGameTime ? 'Game Time' : 'Real Time'}</h6>
                  </div>
                  <p className="card-text">{useGameTime ? selectedRun.gameTime : selectedRun.realTime}</p>
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="card p-3 shadow-sm">
                  <div className="d-flex align-items-center">
                    <Clock className="text-success me-2" />
                    <h6 className="card-title">Real Time</h6>
                  </div>
                  <p className="card-text">{selectedRun.realTime}</p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <h6 className="mb-2">Run Visualization</h6>
              <div className="overflow-auto">
              <RunVisualization 
                  segments={selectedRun.segments} 
                  totalTime={selectedRun.segments.reduce((sum, segment) => sum + segment.timeInSeconds, 0)} 
                  bestSegmentTimes={bestSegmentTimes}
                  previousBestRun={previousBestRun ? getSegmentsForBestRun(segments, findPreviousBestRun(selectedRun).id, useGameTime) : []}
                />
              </div>
            </div>
            <button 
              className="btn btn-primary mt-3"
              onClick={() => setSelectedRun(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Shareable Link Modal */}
      <Modal show={showShareModal} onHide={() => setShowShareModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Shareable Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group">
            <input type="text" className="form-control" value={shareableLink} readOnly />
            <Button 
              variant="outline-secondary" 
              onClick={() => navigator.clipboard.writeText(shareableLink)}
            >
              Copy
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowShareModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Video Modal */}
      <Modal show={showVideoModal} onHide={() => setShowVideoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Attach Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Video URL (YouTube or Twitch)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter YouTube or Twitch video URL"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start Time (in seconds)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter start time"
                value={videoStartTime}
                onChange={(e) => setVideoStartTime(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowVideoModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleVideoSubmit}>
            Attach Video
          </Button>
        </Modal.Footer>
      </Modal>

{/* Timestamps Modal */}
<Modal show={showTimestampsModal} onHide={() => setShowTimestampsModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Video Timestamps</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(!videoStartTime || videoStartTime === '0') && (
          <p>Make sure your video start offset is correct</p>
        )}
        <Form.Control
          as="textarea"
          rows={10}
          value={timestamps}
          readOnly
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowTimestampsModal(false)}>
          Close
        </Button>
        <Button 
          variant="primary" 
          onClick={() => {
            navigator.clipboard.writeText(timestamps);
            setShowTimestampsModal(false);
          }}
        >
          Copy to Clipboard
        </Button>
      </Modal.Footer>
    </Modal>



    </div>
  );
};

export default StraySpeedrunDashboard;
